import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {NativeSelect, TextField, Typography, OutlinedInput, SvgIcon, debounce, Tooltip} from '@mui/material';
import {ReactComponent as ArrowDown} from '../../assets/icons/arrowDown.svg';
import {useTranslation} from 'react-i18next';
import { formatNumberOrCnc } from '../../tools/helper';

export interface ValvePilotInputOption {
    [key: string]: string | undefined;
}
export interface ValvePilotInputProps {
    type: string | 'select' | 'text' | 'number' | 'placeholder' | 'btn' | 'icon-btn' | 'info';
    defaultValue?: string | number |boolean;
    disabled?: boolean;
    grid?: number;
    icon?: string;
    id?: string;
    info?: string;
    options?: ValvePilotInputOption[];
    orderByValue?: boolean;
    readonly?: boolean;
    required?: boolean;
    tabIndex?: number;
    value?: string | number| boolean | null;
    onChange?: (value?: string | number | boolean) => void;
    min?: number;
    max?: number;
    step?: string;
    biggerNull?: boolean;
    inputmode?: string;
    dynamicRound?: boolean,
    lng?: string
}
const ValvePilotInput: FC<ValvePilotInputProps> = (props) => {
    const {
        disabled,
        id,
        orderByValue,
        readonly,
        required,
        tabIndex,
        type,
        onChange,
        min,
        max,
        biggerNull,
        step,
        inputmode,
        dynamicRound,
        lng
    } = props;

    const {t, i18n} = useTranslation();
   
    const [value, setValue] = useState(dynamicRound ? formatNumberOrCnc(Number(props.value), lng || i18n.language) : props.value);

    const onChangeDebounced = useCallback(
        debounce(onChange!, 1000)
    , []);

    useEffect(() => {
        if (value !== props.value) {
            setValue( dynamicRound ? formatNumberOrCnc(Number(props.value), lng || i18n.language) : props.value)
        }
    }, [props.value]);

    useEffect(() => {
        if (lng) setValue( dynamicRound ? formatNumberOrCnc(Number(props.value), lng) : props.value)
    }, [lng]);


    const onKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            changeValue(e)
        }
    }

    const changeValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> ) => {
        let valueParam: string | number | boolean | undefined;
        switch (e.target.value) {
            case 'true':
                valueParam = true;
                break;
            case 'false':
                valueParam = false;
                break;
            default:
                valueParam = (type === 'number' ? (e.target.value || (Number(e.target.value) === 0) ? Number(e.target.value) : undefined) : (type === 'text' && inputmode === 'numeric') ? 
                    Number(value)
                : e.target.value);
                break;
        }
        if(inputmode && inputmode === 'numeric' && onChange && valueParam !== props.value ) {
            if(valueParam || valueParam === 0) onChange(valueParam);
        } else if (valueParam !== props.value) {
            setValue(type === 'number' ? valueParam || 0 : valueParam);
            if (onChange) {
                onChange(valueParam);
                // ToDo: Failt bei onChange Inlet
                // onChangeDebounced(value);
            }
        }

    }

    const handleNumericInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(inputmode && inputmode === 'numeric') {
            e.target.value = e.target.value.includes(",") || e.target.value.includes(".") ? e.target.value : e.target.value.replace(/^0+/, '');
            if (e.target.value) {
                const reNonNumeric = new RegExp(/^(-)|[^0-9.,]+/g);
                if (!e.target.value.match("^[0-9]+$")){
                    e.target.value = e.target.value.replace(reNonNumeric, "$1").replace(',', '.');
                }
                e.target.value = e.target.value.replace(',', '.');
                e.target.value.split('.').length < 3 && setValue(e.target.value);
            } else {
                setValue(0)
            }
        } 
    }

    let selectOptions;

    if (orderByValue) {
        selectOptions = props.options?.sort((a, b) => {
            let valueA = Object.values(a)![0]?.toLowerCase();
            let valueB = Object.values(b)![0]?.toLowerCase();
            return valueA! > valueB! ? 1 : -1;
        })
    } else {
        selectOptions = props.options
    }

    switch (type) {
        case 'info':
            return <Typography color={'primary'} sx={{display: 'flex', alignItems: 'center', height: '100%'}}>{value}</Typography>
        case 'placeholder':
            return <></>;
        case 'select':
            return <NativeSelect variant={'outlined'} size={'small'} fullWidth
                                 disabled={disabled}
                                 readOnly={readonly}
                                 required={required}
                                 value={value}
                                 onChange={(e) => changeValue(e)}
                                 inputProps={{ tabIndex: tabIndex }}
                                 input={<OutlinedInput id={id} />}
                                 IconComponent={({ className }) => {
                                     return <SvgIcon component={ArrowDown} className={className} inheritViewBox/>
                                 }}>
                {!required && <option value={''}></option>}
                {selectOptions?.map((o: ValvePilotInputOption) => {
                    return <option key={Object.keys(o).toString()} value={Object.keys(o)}>{Object.values(o)}</option>
                })}
            </NativeSelect>;
        default:
        case 'text':
        case 'number':
            return <> 
                <Tooltip title={(inputmode === 'numeric' && (min || min === 0) && Number(value) < min) ? t('Alert.INFO.PLEASE_ENTER_A_VALUE_GREATER_THAN', {min: min}) : (inputmode === 'numeric' && (max || max === 0) && Number(value) > max)  ? t('Alert.INFO.PLEASE_ENTER_A_VALUE_LESS_THAN', {max: max}) : undefined} arrow >
                    <TextField type={type}
                              variant={'outlined'}
                              size={'small'}
                              fullWidth
                              disabled={disabled}
                              required={required}
                              InputProps={{ inputProps: { tabIndex: tabIndex, min: biggerNull ? (min && min > 0 ? min : (step ?? '0.000001')) : min, max: max, step: step ?? 'any' } }}
                              value={(value) ?? ((type === 'text' && !inputmode) ? '' : 0)}
                              onChange={(e) => inputmode && inputmode === 'numeric' ? handleNumericInput(e) : setValue(e.target.value)}
                              onBlur={(e) => changeValue(e)}
                              onKeyDown={onKeyDown}
                              error={((inputmode === 'numeric' && (min || min === 0) && Number(value) < min) || (inputmode === 'numeric' && (max || max === 0) && Number(value) > max)) ? true : false}
                    />
                </Tooltip>
            </>;
    }
};

export default ValvePilotInput;

