import React, {
    Dispatch,
    FC,
    SetStateAction,
    useContext
} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Grid,
    IconButton,
    styled,
    SvgIcon,
    AlertProps
} from '@mui/material';

import {
    FluidType,
    generalSettingsType,
    operatingPointType,
    supportedUnitIdsType,
    UnitIdValueType
} from './ValvePilot';
import ValvePilotAccordion from './ValvePilotAccordion';
import ValvePilotInput from './ValvePilotInput';
import ValvePilotTableRow from './ValvePilotTableRow';

import pipeLimitOptions from '../../assets/PipeLimitOptions.json';
import axios from 'axios';
import {ConfigContext} from '../../App';

import {ReactComponent as Add} from '../../assets/icons/columnAdd.svg';
import {ReactComponent as Remove} from '../../assets/icons/columnRemove.svg';

interface ValvePilotSizingInputProps {
    columnsCount: number;
    operatingPoints: operatingPointType[];
    supportedUnitIds: supportedUnitIdsType;
    generalSettings: generalSettingsType;
    isMediumFluid: boolean;
    isMediumSteam: boolean;
    isCalculated: boolean;
    fluids: FluidType[];

    setColumnsCount: (columnsCount: number) => void;
    setIsCalculated: (isCalculated: boolean) => void;

    setIsMediumFluid: (isMediumFluid: boolean) => void;
    setIsMediumSteam: (isMediumSteam: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;
    setHasChanges: (hasChanges: boolean) => void;
    setOperatingPoints: Dispatch<SetStateAction<operatingPointType[]>>;
    setGeneralSettings: Dispatch<SetStateAction<generalSettingsType|undefined>>;
    clearCalculation: () => void;
    setAlerts: (alerts: AlertProps[]) => void;
}


const Group = styled(Box)({
    marginBottom: '1.5rem',
    '&:last-child': {
        marginBottom: 0
    }
});


const ValvePilotSizingInput: FC<ValvePilotSizingInputProps> = (props) => {
    const config = useContext(ConfigContext);
    const {
        generalSettings,
        operatingPoints,
        columnsCount,
        supportedUnitIds,
        isMediumFluid,
        isMediumSteam,
        fluids,

        setIsCalculated,
        setColumnsCount,

        setIsMediumFluid,
        setIsMediumSteam,
        setOperatingPoints,
        setGeneralSettings,
        setIsLoading,
        setHasChanges,
        setAlerts,
        clearCalculation
    } = props;

    const {t, i18n} = useTranslation();
    
    let tabIndex = 1;
    let tabIndexPerCol= 1;
    const getTabIndex = (col: number) => {
        if (col === 0) tabIndexPerCol = 0;
        return Number((col + 2).toString() + tabIndexPerCol++);
    }

    const fluidsOptions = fluids.map((fluid) => {return {[fluid.ID]: t('Fluid.'+fluid.Name)}});
    const getFlowRateQOptions = () => {
        if (supportedUnitIds) {
            if (isMediumSteam) {
                return [...supportedUnitIds.MassFlow ?? []];
            } else {
                if (isMediumFluid) {
                    return [...supportedUnitIds.FluidVolumeFlow ?? [], ...supportedUnitIds.MassFlow ?? []];
                } else {
                    return [...supportedUnitIds.MassFlow ?? [], ...supportedUnitIds.GasVolumeFlow ?? []];
                }
            }
        }
        return [];
    }

    const getTmpValue = async (value:string|number|boolean|UnitIdValueType|undefined, srcUnitId:string, targetUnitId:string, operatingPointKey: string, index: number) => {
        let conversionUrl;

        switch (operatingPointKey) {
            case 'FlowRateQ':
                if (isMediumSteam) {
                    conversionUrl = '/Conversion/SteamFlow/' + value + '/for/' + generalSettings.FluidId;
                } else if (isMediumFluid) {
                    conversionUrl = '/Conversion/FluidFlow/' + value + '/for/' + generalSettings.FluidId;
                } else  {
                    conversionUrl = '/Conversion/GasFlow/' + value + '/for/' + generalSettings.FluidId;
                }
                break;
            case 'Viscosity':
                conversionUrl = '/Conversion/Viscosity/' + value + '/for/' + generalSettings.FluidId;
                break;
            default:
                conversionUrl = '/Conversion/' + value;
                break;
        }

        return await axios.get(config?.proxyUrl + `/UnitConversions/api/` + config?.apiVersion + conversionUrl ,  {
            params: (operatingPointKey === 'FlowRateQ' && isMediumFluid) ? {
                srcUnitID: srcUnitId,
                targetUnitId: targetUnitId,
                T1: operatingPoints[index].TemperaturT1.Value,
                T1UnitId: operatingPoints[index].TemperaturT1.UnitId,
                p1: operatingPoints[index].InletPressureP1.Value,
                p1UnitId: operatingPoints[index].InletPressureP1.UnitId
            } : {
                srcUnitID: srcUnitId,
                targetUnitId: targetUnitId
            }
        }).then(response => response.data).catch((error) => {
            console.error(error);
            setAlerts([{
                severity: 'error',
                children: t('Alert.ERROR.ERR_INVALID_RESPONSE', {httpError: error.status})
            }]);
        });
    }

    const getSaturatedSteamTemp = async (pressureValue: string|number|boolean|UnitIdValueType, pressureUnitId: string, index: number) => {
        const temperatureUnitId = operatingPoints[index].TemperaturT1.tmpUnitId ?? operatingPoints[index].TemperaturT1.UnitId;
        await axios.get(config?.proxyUrl + `/ValveCalc/api/` + config?.apiVersion + `/Calculation/Sattdampf/T/` + pressureValue, {
            params: {
                pressureUnitId: pressureUnitId,
                temperatureUnitId: temperatureUnitId
            }
        }).then((res) => {
            let operatingPointsTmp = operatingPoints as operatingPointType[];

            operatingPointsTmp[index] = {
                ...operatingPoints?.[index],
                TemperaturT1: {
                    ...operatingPoints?.[index].TemperaturT1,
                    Value: res.data.TSattdampf.Value ? Number(res.data.TSattdampf.Value).toFixed(5) : undefined
                }
            } as operatingPointType;

            setOperatingPoints(operatingPointsTmp);
        }).catch((error) => {
            console.error(error);
            setAlerts([{
                severity: 'error',
                children: t('Alert.ERROR.ERR_INVALID_RESPONSE', {httpError: error.status})
            }]);
        });
    }

    const getVelocityLimit = async (pressureValue: string|number|boolean|UnitIdValueType, pressureUnitId: string, operatingPointTypeKey: 'InletPressureP1' | 'OutletPressureP2', fluidId: string|number|boolean|UnitIdValueType|undefined, generalSettingsTmp: generalSettingsType): Promise<generalSettingsType> => {
        let key: string;
        if (operatingPointTypeKey === 'InletPressureP1') {
            key = 'InletVelocityLimit';
        } else if (operatingPointTypeKey === 'OutletPressureP2') {
            key = 'OutletVelocityLimit';
        } else {
            throw new Error(`Invalid operatingPointTypeKey: ${operatingPointTypeKey}`);
        }

        const values = operatingPoints.map(op => op[operatingPointTypeKey].tmpValue ?? op[operatingPointTypeKey].Value).sort();
        let lowestPressureValue = (values[0]! < pressureValue!) ? values[0] : pressureValue;

        try {
            const response = await axios.get(
                `${config?.proxyUrl}/ValveCalc/api/${config?.apiVersion}/Calculation/VelocityLimit/${lowestPressureValue}/forFluid/${fluidId}`,
                {
                    params: { pressureUnitId }
                }
            );

            return {
                ...generalSettingsTmp,
                [key]: {
                    ...response.data.VelocityLimit,
                    Value: response.data.VelocityLimit.Value ? Number(response.data.VelocityLimit.Value).toFixed(5) : undefined
                }
            };
        } catch (error) {
            console.error('Error fetching velocity limit:', error);
            return generalSettingsTmp;
        }
    }

    const fillObject = async (key: keyof generalSettingsType | 'InletLimit' | 'OutletLimit', value: string|number|boolean|UnitIdValueType|undefined) => {
        setHasChanges(true);
        switch (key) {
            case 'InletLimit':
                key = generalSettings.useVelocityLimit ? 'InletVelocityLimit' : 'InletPipeLimit';
                value = {
                    ...generalSettings[key],
                    Value: Number(value),
                    UnitId: generalSettings[key]?.tmpUnitId ?? generalSettings[key]?.UnitId,
                    tmpValue: undefined,
                    tmpUnitId: undefined
                };
                break;
            case 'OutletLimit':
                key = generalSettings.useVelocityLimit ? 'OutletVelocityLimit' : 'OutletPipeLimit';
                value = {
                    ...generalSettings[key],
                    Value: Number(value),
                    UnitId: generalSettings[key]?.tmpUnitId ?? generalSettings[key]?.UnitId,
                    tmpValue: undefined,
                    tmpUnitId: undefined
                };
                break;
            case 'FluidId':
                const fluid = fluids!.find((f: FluidType) => f.ID === Number(value));
                if (fluid) {
                    clearCalculation();
                    setIsMediumFluid(fluid!.isMediumFluid);
                    setIsMediumSteam(fluid!.isMediumSteam);
                    setOperatingPoints((operatingPoints) => {
                        return operatingPoints?.map((op) => {
                            const unitId = fluid!.isMediumSteam ? 'kg/h' : (fluid!.isMediumFluid ? 'm3/h' : 'm3/h{norm}');
                            op.FlowRateQ.UnitId= unitId;
                            op.FlowRateQ.tmpUnitId= (fluid!.isMediumFluid === isMediumFluid && fluid!.isMediumSteam === isMediumSteam) ? op.FlowRateQ.tmpUnitId : undefined;
                            op.FlowRateQ.tmpValue= (fluid!.isMediumFluid === isMediumFluid && fluid!.isMediumSteam === isMediumSteam) ? op.FlowRateQ.tmpValue : undefined;
                            return op;
                        });
                    });
                    if(fluid!.isMediumSteam) {
                        operatingPoints.forEach(async (op, index) => {
                            if((op.InletPressureP1.tmpValue ?? op.InletPressureP1.Value) && (op.InletPressureP1.tmpUnitId ?? op.InletPressureP1.UnitId)) {
                                await getSaturatedSteamTemp((op.InletPressureP1.tmpValue ?? op.InletPressureP1.Value)!, (op.InletPressureP1.tmpUnitId ?? op.InletPressureP1.UnitId) ?? '', index);
                            }
                        })
                    }

                    let generalSettingsTmp: generalSettingsType = {
                        ...generalSettings,
                        [key as string]: value
                    };

                    generalSettingsTmp = await getVelocityLimit((operatingPoints[0].InletPressureP1.tmpValue ?? operatingPoints[0].InletPressureP1.Value) ?? 0, (operatingPoints[0].InletPressureP1.tmpUnitId ?? operatingPoints[0].InletPressureP1.UnitId) ?? '', 'InletPressureP1', value, generalSettingsTmp);
                    generalSettingsTmp = await getVelocityLimit((operatingPoints[0].OutletPressureP2.tmpValue ?? operatingPoints[0].OutletPressureP2.Value) ?? 0, (operatingPoints[0].OutletPressureP2.tmpUnitId ?? operatingPoints[0].OutletPressureP2.UnitId) ?? '', 'OutletPressureP2', value, generalSettingsTmp);

                    if (generalSettingsTmp) {
                        setGeneralSettings(generalSettingsTmp);
                    }
                }
                break;
        }
        if (key !== 'FluidId') {
            setGeneralSettings((prevState) => ({...prevState, [key]: value}));
        }
    }

    const fillOperatingPoints = async (key: keyof operatingPointType, value: string|number|boolean|UnitIdValueType|undefined, index:number) => {
        setHasChanges(true);
        let operatingPointsTmp = operatingPoints as operatingPointType[];

        switch (key) {
            case 'AdditionalInformation':
            case 'Flow':
                operatingPointsTmp[index] = {
                    ...operatingPoints?.[index],
                    [key]: value
                }
                break;
            case 'InletPressureP1':
            case 'OutletPressureP2':
                operatingPointsTmp[index] = {
                    ...operatingPoints?.[index],
                    [key]: {
                        UnitId: operatingPoints?.[0]?.[key]?.tmpUnitId ?? operatingPoints?.[0]?.[key]?.UnitId,
                        Value: (value ? Number(value) : undefined)
                    }
                } as operatingPointType;
                break;
            default:
                operatingPointsTmp[index] = {
                    ...operatingPoints?.[index],
                    [key]: {
                        UnitId: operatingPoints?.[0]?.[key]?.tmpUnitId ?? operatingPoints?.[0]?.[key]?.UnitId,
                        Value: (value ? Number(value) : undefined)
                    }
                } as operatingPointType;
                break;
        }
        setOperatingPoints([...operatingPointsTmp]);

        if (value && key && (key === 'InletPressureP1' || key === 'OutletPressureP2')) {
            if (isMediumSteam && key === 'InletPressureP1') {
                await getSaturatedSteamTemp(value, (operatingPoints?.[0]?.[key]?.tmpUnitId ?? operatingPoints?.[0]?.[key]?.UnitId) ?? '', index);
            }

            // if (!isCalculated) {
            if (index === 0) {
                let generalSettingsTmp = await getVelocityLimit(value, (operatingPoints?.[0]?.[key]?.tmpUnitId ?? operatingPoints?.[0]?.[key]?.UnitId) ?? '', key, generalSettings.FluidId, {...generalSettings});
                if (generalSettingsTmp) {
                    setGeneralSettings(generalSettingsTmp);
                }
            }
            // }
        }

    }

    const fillOperatingPointsUnitIds = async (key: keyof operatingPointType, unitId: string) => {
        // setHasChanges(true);
        setIsLoading(true);
        const operatingPointsTmp = await Promise.all(
            operatingPoints?.map(async (operatingPoint,index) => {
                const operatingPointItem = operatingPoint[key] as UnitIdValueType;

                if(operatingPointItem?.Value && operatingPointItem.UnitId){
                    const tmpValue = await getTmpValue(operatingPointItem.Value, operatingPointItem.UnitId, unitId, key, index);

                    return {
                        ...operatingPoint,
                        [key]: {
                            ...operatingPointItem,
                            Value: tmpValue,
                            UnitId: unitId
                        }
                    }
                } else {
                    return {
                        ...operatingPoint,
                        [key]: {
                            ...operatingPointItem,
                            UnitId: unitId
                        }
                    }
                }
            })
        )
        setOperatingPoints(operatingPointsTmp);
        setIsLoading(false);
    }

    const fillUebergreifendeNutzereingabenUnitIds = async (key: keyof generalSettingsType | 'InletLimit' | 'OutletLimit', unitId: string) => {
        // setHasChanges(true);

        switch (key) {
            case 'InletLimit':
                key = generalSettings.useVelocityLimit ? 'InletVelocityLimit' : 'InletPipeLimit';
                break;
            case 'OutletLimit':
                key = generalSettings.useVelocityLimit ? 'OutletVelocityLimit' : 'OutletPipeLimit';
                break;
        }

        let uebergreifendeNutzereingabenTmp;
        const uebergreifendeNutzereingabenItem = generalSettings[key] as UnitIdValueType;

        if (uebergreifendeNutzereingabenItem.Value && uebergreifendeNutzereingabenItem.UnitId) {
            const tmpValue = await getTmpValue(uebergreifendeNutzereingabenItem.tmpValue ?? uebergreifendeNutzereingabenItem.Value, uebergreifendeNutzereingabenItem.tmpUnitId ?? uebergreifendeNutzereingabenItem.UnitId, unitId, key,0);
            uebergreifendeNutzereingabenTmp = {
                ...generalSettings,
                [key]: {
                    ...uebergreifendeNutzereingabenItem,
                    Value: tmpValue,
                    UnitId: unitId
                }
            }
        } else {
            uebergreifendeNutzereingabenTmp = {
                ...generalSettings,
                [key]: {
                    ...uebergreifendeNutzereingabenItem,
                    UnitId: unitId
                }
            }
        }
        setGeneralSettings(uebergreifendeNutzereingabenTmp);
    }

    const fillVelocityLimit = (value: boolean) => {
        setHasChanges(true);

        setGeneralSettings((prevState?: generalSettingsType) => ({
            ...prevState,
            useVelocityLimit: value
        }));
    }

    const addColum = () => {
        setIsCalculated(false);
        setColumnsCount(columnsCount+1);
    }

    const removeColum = () => {
        setHasChanges(true);
        setColumnsCount(columnsCount-1);
    }

    return <ValvePilotAccordion title={t('Global.TITLE_SIZING_INPUT')} defaultExpanded>
        <Group>
            <ValvePilotTableRow title={t('Input.VALVE_TYPE')} info={t('Info.Input.VALVE_TYPE', '')}>
                <Grid item xs={3}>
                    <ValvePilotInput type={'select'} value={generalSettings.ValveType}
                                     options={[
                                         {'PRESSURE_REDUCING_VALVE': t('ValveType.PRESSURE_REDUCING_VALVE')},
                                         {'BACK_PRESSURE_REGULATOR': t('ValveType.BACK_PRESSURE_REGULATOR')}
                                     ]}
                                     onChange={(e) => fillObject('ValveType', e)}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>
            </ValvePilotTableRow>

            <ValvePilotTableRow title={t('Input.FLUID_NAME')} info={t('Info.Input.FLUID_NAME', '')}>
                <Grid item xs={3}>
                    <ValvePilotInput type={'select'} value={generalSettings.FluidId}
                                     options={fluidsOptions} onChange={(e) => fillObject('FluidId', e)}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>
                <Grid item xs={3}>
                    <ValvePilotInput type={'info'} value={t('FluidState.Label') + ': ' + (isMediumFluid ? t('FluidState.Incompressible') : t('FluidState.Compressible'))}/>
                </Grid>
            </ValvePilotTableRow>

            <ValvePilotTableRow title={t('Input.TAG_NO')} info={t('Input.Info.TAG_NO', '')}>
                <Grid item xs={3}>
                    <ValvePilotInput type={'text'} value={generalSettings.tag} onChange={(e) => fillObject('tag', e)} tabIndex={tabIndex++}/>
                </Grid>
            </ValvePilotTableRow>
        </Group>

        <Group>
            <ValvePilotTableRow title={t('Input.ADDITIONAL_INFORMATION')} info={t('Input.Info.ADDITIONAL_INFORMATION', '')}>
                <Grid item xs={1}/>
                    {Array.from({ length: columnsCount }, (_, i) => <Grid key={'AdditionalInformation' + i} item xs={1}>
                    <ValvePilotInput type={'text'}
                                     value={operatingPoints[i]?.AdditionalInformation}
                                     onChange={(e) => fillOperatingPoints('AdditionalInformation', e, i)}
                                     tabIndex={getTabIndex(i)}/>
                </Grid>)}

                <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    {columnsCount > 1 && <IconButton aria-label={t('Buttons.REMOVE')} onClick={removeColum} sx={{marginRight: 1}}>
                        <SvgIcon component={Remove} inheritViewBox fontSize={'large'}/>
                    </IconButton>}
                    {t('Global.COLUMN', 'Column')}
                    {columnsCount < 6 && <IconButton aria-label={t('Buttons.ADD')} onClick={addColum} sx={{marginLeft: 1}}>
                        <SvgIcon component={Add} inheritViewBox fontSize={'large'}/>
                    </IconButton>}
                </Grid>
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('Input.INLET_PRESSURE_P1')} info={t('Info.Input.INLET_PRESSURE_P1', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'}
                                     // defaultValue={'bar{gauge}'}
                                     value={operatingPoints[0]?.InletPressureP1?.tmpUnitId ?? operatingPoints[0]?.InletPressureP1?.UnitId}
                                     options={supportedUnitIds.Pressure}
                                     onChange={(e) => fillOperatingPointsUnitIds('InletPressureP1', e as string)}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>

                {Array.from({ length: columnsCount }, (_, i) => <Grid key={'InletPressureP1' + i} item xs={1}>
                    <ValvePilotInput type={'text'}
                                     inputmode={'numeric'}
                                     dynamicRound
                                     lng={i18n.language}
                                     value={operatingPoints[i]?.InletPressureP1?.tmpValue ?? operatingPoints[i]?.InletPressureP1?.Value}
                                     min={(operatingPoints[i]?.OutletPressureP2?.tmpValue ?? operatingPoints[i]?.OutletPressureP2?.Value) ?? undefined}
                                     onChange={(e) => fillOperatingPoints('InletPressureP1', e, i)}
                                     tabIndex={getTabIndex(i)} required/>
                </Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('Input.OUTLET_PRESSURE_P2')} info={t('Info.Input.OUTLET_PRESSURE_P2', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'}
                                     // defaultValue={'bar{gauge}'}
                                     value={operatingPoints[0]?.OutletPressureP2?.tmpUnitId ?? operatingPoints[0]?.OutletPressureP2?.UnitId}
                                     options={supportedUnitIds.Pressure}
                                     onChange={(e) => fillOperatingPointsUnitIds('OutletPressureP2', e as string)}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>

                {Array.from({ length: columnsCount }, (_, i) => <Grid key={"OutletPressureP2" + i} item xs={1}>
                    <ValvePilotInput type={'text'}
                                     inputmode={'numeric'}
                                     dynamicRound
                                     lng={i18n.language}

                                     value={operatingPoints[i]?.OutletPressureP2?.tmpValue ?? operatingPoints[i]?.OutletPressureP2?.Value}
                                     max={(operatingPoints[i]?.InletPressureP1?.tmpValue ?? operatingPoints[i]?.InletPressureP1?.Value) ?? undefined}
                                     onChange={(e) => fillOperatingPoints('OutletPressureP2', e, i)}
                                     tabIndex={getTabIndex(i)} required/>
                </Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('Input.TEMPERATURE_T1')} info={t('Info.Input.TEMPERATURE_T1', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'}
                                     // defaultValue={'Cel'}
                                     value={operatingPoints[0]?.TemperaturT1?.tmpUnitId ?? operatingPoints[0]?.TemperaturT1?.UnitId}
                                     options={supportedUnitIds.Temperature}
                                     onChange={(e) => fillOperatingPointsUnitIds('TemperaturT1', e as string)}
                                     tabIndex={tabIndex++}
                                     orderByValue required
                    />
                </Grid>

                {Array.from({ length: columnsCount }, (_, i) => <Grid key={'TemperaturT1' + i} item xs={1}>
                    <ValvePilotInput type={'text'}
                                     inputmode={'numeric'}
                                     dynamicRound
                                     lng={i18n.language}

                                     // defaultValue={25}
                                     value={operatingPoints[i]?.TemperaturT1?.tmpValue || operatingPoints[i]?.TemperaturT1?.Value ? Number(Number(operatingPoints[i]?.TemperaturT1?.tmpValue ?? operatingPoints[i]?.TemperaturT1?.Value).toFixed(1)) : undefined}
                                     onChange={(e) => fillOperatingPoints('TemperaturT1', e, i)}
                                     tabIndex={getTabIndex(i)}
                                     disabled={isMediumSteam}
                                     required/>
                </Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('Input.FLOW_RATE_Q')} info={t('Info.Input.FLOW_RATE_Q', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'} value={operatingPoints[0]?.FlowRateQ?.tmpUnitId ?? operatingPoints[0]?.FlowRateQ?.UnitId}
                                     // defaultValue={'m3/h{norm}'}
                                     options={getFlowRateQOptions()}
                                     onChange={(e) => fillOperatingPointsUnitIds('FlowRateQ', e as string)}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>

                {Array.from({ length: columnsCount }, (_, i) =>
                    <Grid key={'FlowRateQ' + i} item xs={1}>
                        <ValvePilotInput type={'text'}
                                         inputmode={'numeric'}
                                         dynamicRound
                                         lng={i18n.language}

                                         value={operatingPoints[i]?.FlowRateQ?.tmpValue ?? operatingPoints[i]?.FlowRateQ?.Value}
                                         onChange={(e) => fillOperatingPoints('FlowRateQ', e, i)}
                                         tabIndex={getTabIndex(i)}
                                         required/>
                    </Grid>
                )}
            </ValvePilotTableRow>
        </Group>

        <Group>
            <ValvePilotTableRow title={t('Input.TYPE')} info={t('Input.Info.TYPE', '')}>
                <Grid item xs={3}>
                    <ValvePilotInput type={'select'} value={generalSettings.useVelocityLimit }
                        // defaultValue={true}
                                     options={[
                                         {true: t('Type.VELOCITY_LIMIT')},
                                         {false: t('Type.PIPE_SIZE')}
                                     ]}
                                     onChange={(e) => fillVelocityLimit(e as boolean)}
                                     tabIndex={tabIndex=100}
                                     orderByValue required/>
                </Grid>
            </ValvePilotTableRow>

            <ValvePilotTableRow title={t('Input.INLET')} info={t('Info.Input.INLET', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'}
                                     value={generalSettings.useVelocityLimit ? (generalSettings?.InletVelocityLimit?.tmpUnitId ?? generalSettings?.InletVelocityLimit?.UnitId) : (generalSettings?.InletPipeLimit?.tmpUnitId ?? generalSettings?.InletPipeLimit?.UnitId)}
                        // defaultValue={uebergreifendeNutzereingaben.useVelocityLimit ? 'm/s' : 'mm{pipe}'}
                                     options={generalSettings.useVelocityLimit ? supportedUnitIds.Velocity : supportedUnitIds.PipeDimensions}
                                     onChange={(e) => fillUebergreifendeNutzereingabenUnitIds('InletLimit', e as string)}
                                     disabled={!generalSettings.useVelocityLimit}
                                     tabIndex={tabIndex++}
                                     orderByValue required/>
                </Grid>
                <Grid item xs={2}>
                    {!generalSettings.useVelocityLimit && <ValvePilotInput type={'select'} value={generalSettings.InletPipeLimit?.tmpValue ?? generalSettings.InletPipeLimit?.Value}
                                                                           options={pipeLimitOptions}
                                                                           onChange={(e) => {fillObject('InletLimit', e); fillObject('OutletLimit', e);}}
                                                                           tabIndex={tabIndex++}
                                                                           required/>}
                    {generalSettings.useVelocityLimit && <ValvePilotInput type={'text'}
                                                                          inputmode={'numeric'}
                                                                          dynamicRound
                                                                          lng={i18n.language}

                                                                          value={generalSettings.InletVelocityLimit?.tmpValue || generalSettings.InletVelocityLimit?.Value ? Number(Number(generalSettings.InletVelocityLimit?.tmpValue ?? generalSettings.InletVelocityLimit?.Value).toFixed(1)) : undefined}
                                                                          onChange={(e) => fillObject('InletLimit', e)} tabIndex={tabIndex++} required biggerNull/>}
                </Grid>
            </ValvePilotTableRow>

            <ValvePilotTableRow title={t('Input.OUTLET')} info={t('Info.Input.OUTLET', '')}>
                <Grid item xs={1}>
                    <ValvePilotInput type={'select'}
                                     value={generalSettings.useVelocityLimit ? (generalSettings?.OutletVelocityLimit?.tmpUnitId ?? generalSettings?.OutletVelocityLimit?.UnitId) : (generalSettings?.OutletPipeLimit?.tmpUnitId ?? generalSettings?.OutletPipeLimit?.UnitId)}
                        // defaultValue={uebergreifendeNutzereingaben.useVelocityLimit ? 'm/s' : 'mm{pipe}'}
                                     options={generalSettings.useVelocityLimit ? supportedUnitIds.Velocity : supportedUnitIds.PipeDimensions}
                                     onChange={(e) => fillUebergreifendeNutzereingabenUnitIds('OutletLimit', e as string)}
                                     disabled={!generalSettings.useVelocityLimit}
                                     tabIndex={tabIndex++}
                                     required/>
                </Grid>
                <Grid item xs={2}>
                    {!generalSettings.useVelocityLimit && <ValvePilotInput type={'select'} value={generalSettings.OutletPipeLimit?.tmpValue ?? generalSettings.OutletPipeLimit?.Value}
                                                                           options={pipeLimitOptions}
                                                                           onChange={(e) => fillObject('OutletLimit', e)}
                                                                           tabIndex={tabIndex++}
                                                                           required/>}
                    {generalSettings.useVelocityLimit && <ValvePilotInput type={'text'}
                                                                          inputmode={'numeric'}
                                                                          dynamicRound
                                                                          lng={i18n.language}

                                                                          value={generalSettings.OutletVelocityLimit?.tmpValue || generalSettings.OutletVelocityLimit?.Value ? Number(Number(generalSettings.OutletVelocityLimit?.tmpValue ?? generalSettings.OutletVelocityLimit?.Value).toFixed(1)) : undefined}
                                                                          onChange={(e) => fillObject('OutletLimit', e)} tabIndex={tabIndex++} required biggerNull/>}
                </Grid>
            </ValvePilotTableRow>
        </Group>
    </ValvePilotAccordion>
};
export default ValvePilotSizingInput;
