import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Grid, styled, AlertProps} from '@mui/material';
import {Check, Clear} from '@mui/icons-material';

import {BerechneteEigenschaftenType, fluidPropertiesType, operatingPointType, ResultType, supportedUnitIdsType, UnitIdValueType} from './ValvePilot';
import ValvePilotAccordion from './ValvePilotAccordion';
import ValvePilotInput, {ValvePilotInputOption} from './ValvePilotInput';
import ValvePilotTableRow from './ValvePilotTableRow';
import ValvePilotTextBox from './ValvePilotTextBox';
import axios from 'axios';
import {ConfigContext} from '../../App';

interface ValvePilotFluidPropertiesProps {
    results: ResultType[];
    isAbsoluteViscosity: false | ValvePilotInputOption | undefined;
    operatingPoints: operatingPointType[];
    supportedUnitIds: supportedUnitIdsType;
    // fillFluidPropertiesUnitIds: (key: keyof fluidPropertiesType; unitId: string; type: keyof ResultType) => void;
    setIsLoading: (isLoading: boolean) => void;
    setResults: (results: ResultType[]) => void;
    isMediumFluid: boolean;
    isMediumSteam: boolean;
    fluidId?: number;
    setAlerts: (alerts: AlertProps[]) => void;
}

const Group = styled(Box)({
    marginBottom: '1rem',
    '&:last-child': {
        marginBottom: 0
    }
});

const ValvePilotFluidProperties: FC<ValvePilotFluidPropertiesProps> = (props) => {
    const config = useContext(ConfigContext);
    const {
        results,
        operatingPoints,
        supportedUnitIds,
        isAbsoluteViscosity,
        setIsLoading,
        setResults,
        isMediumFluid,
        isMediumSteam,
        fluidId,
        setAlerts
    } = props;
    const {t, i18n} = useTranslation();
    // const theme = useTheme();

    const getTmpValue = async (value:string|number|boolean|UnitIdValueType|undefined, srcUnitId:string, targetUnitId:string, operatingPointKey: string) => {
        let conversionUrl;

        switch (operatingPointKey) {
            case 'FlowRateQ':
                if (isMediumSteam) {
                    conversionUrl = '/Conversion/SteamFlow/' + value + '/for/' + fluidId;
                } else if (isMediumFluid) {
                    conversionUrl = '/Conversion/FluidFlow/' + value + '/for/' + fluidId;
                } else  {
                    conversionUrl = '/Conversion/GasFlow/' + value + '/for/' + fluidId;
                }
                break;
            case 'Viscosity':
                conversionUrl = '/Conversion/Viscosity/' + value + '/for/' + fluidId;
                break;
            default:
                conversionUrl = '/Conversion/' + value;
                break;
        }

        return await axios.get(config?.proxyUrl + `/UnitConversions/api/` + config?.apiVersion + conversionUrl , {
            params: {
                srcUnitID: srcUnitId,
                targetUnitId: targetUnitId
            }
        }).then(response => response.data).catch((error) => {
            console.error(error);
            setAlerts([{
                severity: 'error',
                children: t('Alert.ERROR.ERR_INVALID_RESPONSE', {httpError: error.status})
            }]);
        });
    }

    const fillFluidPropertiesUnitIds = async (key: keyof fluidPropertiesType, unitId: string, resultType: keyof ResultType) => {
        if (results) {
            setIsLoading(true);
            const resultsTmp = await Promise.all(
                results.map(async (result: ResultType) => {
                    const fluidPropertyItem = isMediumFluid ? result.BerechneteEigenschaftenMediumFluid?.[key] : result.BerechneteEigenschaftenMediumGas?.[key] as UnitIdValueType;
                    if(fluidPropertyItem?.Value && fluidPropertyItem?.UnitId){
                        const value = await getTmpValue(fluidPropertyItem.Value, fluidPropertyItem.UnitId, unitId, key);
                        return {
                            ...result,
                            [resultType]: {
                                ...result[resultType] as BerechneteEigenschaftenType,
                                [key]: {
                                    ...fluidPropertyItem,
                                    Value: value,
                                    UnitId: unitId
                                }
                            }
                        }
                    } else {
                        return {
                            ...result,
                            [resultType]: {
                                ...result[resultType] as BerechneteEigenschaftenType,
                                [key]: {
                                    ...fluidPropertyItem,
                                    UnitId: unitId
                                }
                            }
                        }
                    }
                })
            )
            setIsLoading(false);
            setResults(resultsTmp);
        }
    }

    return <ValvePilotAccordion title={t('Global.TITLE_FLUID_PROPERTIES')}>
        <ValvePilotTableRow title={t('FluidState.Label') + ' (' + t('FluidState.Compressible') + ')'}>
            <Grid item xs={1}><ValvePilotTextBox></ValvePilotTextBox></Grid>
            {results.map((r, i: number) => <Grid key={i} item xs={1}>
                {/*<ValvePilotTextBox sx={{color: (!!r.isMediumFluidTmp !== r.isMediumFluid) ? theme.palette.warning.main : undefined }} tooltip={<Box sx={{padding: 1}}>{r.isMediumFluid ? t('FluidState.Incompressible') :  t('FluidState.Compressible')}</Box>}>*/}
                <ValvePilotTextBox tooltip={<Box sx={{padding: 1}}>{r.isMediumFluid ? t('FluidState.Incompressible') :  t('FluidState.Compressible')}</Box>}>
                    {r.isMediumFluid ? <Clear/> : <Check/>} {r.isMediumFluid ? t('FluidState.Compressible_No') : t('FluidState.Compressible_Yes')}
                </ValvePilotTextBox>
            </Grid>)}
        </ValvePilotTableRow>

        {!results![0]!.isMediumFluid && <Group>
            <ValvePilotTableRow title={t('FluidProps.SPECIFIC_HEAT_RATIO')}>
                <Grid item xs={1}><ValvePilotTextBox> &nbsp; </ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumGas?.SpecificHeatRatio).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.DENSITY')}>
                <Grid item xs={1}><ValvePilotInput type={'select'} value={results[0].BerechneteEigenschaftenMediumGas?.Density?.UnitId} options={supportedUnitIds.Density} onChange={(e) => fillFluidPropertiesUnitIds('Density', e as string, 'BerechneteEigenschaftenMediumGas')} required/></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumGas?.Density?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.MOLECULAR_WEIGHT')}>
                <Grid item xs={1}><ValvePilotTextBox>{results[0].BerechneteEigenschaftenMediumGas?.MolecularWeight?.UnitId}</ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumGas?.MolecularWeight?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.COMPRESS_FACTOR_Z')}>
                <Grid item xs={1}><ValvePilotTextBox> &nbsp; </ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumGas?.CompressibilityFactorZ).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.FLOW')}>
                <Grid item xs={1}><ValvePilotTextBox></ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox>{r.Flow === 'TURBULENT' ? t('Flow.Turbulent') : t('Flow.Laminar')}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
        </Group>}

        {results[0]!.isMediumFluid && <Group>
            <ValvePilotTableRow title={t('FluidProps.VAPOR_PRESSURE')}>
                <Grid item xs={1}><ValvePilotTextBox>{t('Unit.Pressure.' + operatingPoints[0]?.InletPressureP1?.UnitId)}</ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumFluid?.Pv?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 1})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.CRITICAL_PRESSURE')}>
                <Grid item xs={1}><ValvePilotTextBox>{t('Unit.Pressure.' + operatingPoints[0]?.InletPressureP1?.UnitId)}</ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumFluid?.Pc?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 1})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.DENSITY')}>
                <Grid item xs={1}><ValvePilotInput type={'select'} value={results[0].BerechneteEigenschaftenMediumFluid?.Density?.UnitId} options={supportedUnitIds.Density} onChange={(e) => fillFluidPropertiesUnitIds('Density', e as string, 'BerechneteEigenschaftenMediumFluid')} required/></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumFluid?.Density?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={isAbsoluteViscosity ? t('FluidProps.ABSOLUTE_VISCOSITY') : t('FluidProps.KINEMATIC_VISCOSITY')}>
                {/*@ts-ignore*/}
                <Grid item xs={1}><ValvePilotInput type={'select'} value={results[0].BerechneteEigenschaftenMediumFluid?.Viscosity?.UnitId} options={[].concat(supportedUnitIds.Viscosity, supportedUnitIds.KinematicViscosity)} onChange={(e) => fillFluidPropertiesUnitIds('Viscosity', e as string, 'BerechneteEigenschaftenMediumFluid')} required/></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox textAlign={'end'}>{Number(r.BerechneteEigenschaftenMediumFluid?.Viscosity?.Value).toLocaleString(i18n.language, {maximumFractionDigits: 5})}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
            <ValvePilotTableRow title={t('FluidProps.FLOW')}>
                <Grid item xs={1}><ValvePilotTextBox></ValvePilotTextBox></Grid>
                {results.map((r, i: number) => <Grid key={i} item xs={1}><ValvePilotTextBox>{r.Flow === 'TURBULENT' ? t('Flow.Turbulent') : t('Flow.Laminar')}</ValvePilotTextBox></Grid>)}
            </ValvePilotTableRow>
        </Group>}
    </ValvePilotAccordion>
};
export default ValvePilotFluidProperties;
