const roundToThreeNonZeroDecimals = (value: number, lg: string | 'EN' | 'DE') => {
    let formattedNumber = value.toFixed(15).replace(/0+$/, '');
    let nonZeroDecimals = formattedNumber.split('.')[1];
    let count = 0;
    let result = [];
    let index = 0;
    for (let digit of nonZeroDecimals) {
        result.push(digit);
        index += 1;
        if (digit !== '0') {
            count += 1;
        }
        if (count === 3) {
            break;
        }
    }
    return Number(formattedNumber).toLocaleString(lg,  {maximumFractionDigits: index}) ;
}

const getFractionDigits = (value: number | undefined) => {
    if(!value) return 0;
    if(0 < value && value < 1) return 3;
    if(1 <= value && value < 10) return 2;
    if(10 <= value && value < 1000) return 1;
    if(value >= 1000) return 0;
    return 0;
}


export const formatNumberOrCnc = (value: number | null | undefined, lg: string | 'EN' | 'DE') => {
    if(value === undefined) {
        return;
    }
    const fractionDigits = getFractionDigits(Number(value));
    
    return  value ? (fractionDigits === 3 ? roundToThreeNonZeroDecimals(Number(value), lg) : Number(value).toLocaleString(lg,  {maximumFractionDigits: fractionDigits})) : 0;
}


