import React, {
    FC,
    useState
} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    styled,
    SvgIcon
} from '@mui/material';
import {LocalShippingOutlined} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';

import {ReactComponent as ArrowRight} from '../../assets/icons/arrowRight.svg';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrowLeft.svg';
import {ReactComponent as Cheapest} from '../../assets/icons/cheapest.svg';
import {ReactComponent as SmallerNominalWidth} from '../../assets/icons/smaller-nominal-width.svg';
import {ReactComponent as LargerNominalWidth} from '../../assets/icons/larger-nominal-width.svg';
import {ReactComponent as WorkingDays} from '../../assets/icons/working-days.svg';
import mBase from '../../assets/images/mbase.png';
import mEdit from '../../assets/images/medit.png';
import mExtra from '../../assets/images/mextra.png';
import i18n from '../../i18n';

export interface ValvePilotProductCardProps {
    product: ValvePilotProduct;
    configuratorUrl?: string;
}

export interface ValvePilotProductProduktRepraesentantFuerKonfigurator {
    A_DN_INLET: number;
    A_DN_OUTLET: number;
    A_P_MAX_INLET: number;
    A_P_MAX_OUTLET: number;
    A_SETTING_RANGE: string;
    A_KVS: number;
    A_TMIN: number;
    A_TMAX: number;
    A_TMAX_STEAM: number;
    A_OPTIONS?: string; // removed
    CONFIGURATOR_OPTIONS: string;
    EASYKAT_CFG_OBJECT: string;
    REQUESTED_OPTIONS?: string; // removed
    DISABLE_CONFIGURATION?: boolean;
}

export interface ValvePilotProductVariants {
    Variant?: string;
    Name?: string;
    ImageURL?: string;
    DeliveryTime?: number;
    DN?: string;
    PN?: string;
    G?: string;
    PSMax?: string;
    P1?: string;
    P2?: string;
    KVS?: string;
    T?: string;
    InfoDe?: string;
    InfoEn?: string;
    LinkDe?: string;
    LinkEn?: string;
    ProduktRepraesentantFuerKonfigurator: ValvePilotProductProduktRepraesentantFuerKonfigurator;
    isCheapest?: boolean;
    diameterLargerThanCalculated?: boolean;
    diameterSmallerThanCalculated?: boolean;
}
export interface ValvePilotProduct {
    ProductId?: string;
    Varianten: ValvePilotProductVariants[];
}

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    padding: '0.5rem',
    '& span.MuiTypography-root ': {
        fontSize: '1rem'
    }
}));

const IconLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    ...theme.typography.body2,
    padding: '0.375rem 1rem',
    display: 'flex',
    alignItems: 'center',
    fontFamily:  theme.typography.fontFamily,
}));

const DetailsContainer = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    padding: '0.5rem',
    height: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const GreyBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily:  theme.typography.fontFamily,
    backgroundColor: theme.palette.grey[300],
    padding: '0 0.5rem',
    fontWeight: 'unset',
    height: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const IconButton = styled(Button)(({ theme }) => ({
    minWidth: 'unset',
    padding: 3,
    '&:hover': {
        color: 'white'
    }
}));

const LabelImage = styled('img')({
    height: 30
});

const ProductImage = styled('img')({
    maxWidth: '100%',
    maxHeight: 170
});

const ValvePilotProductCard: FC<ValvePilotProductCardProps> = ({product, configuratorUrl}) => {
    const [showVariantIndex, setShowVariantIndex] = useState(0);
    const {t} = useTranslation();

    const increaseShowVariantIndex = () => {
        if (showVariantIndex+1 > product.Varianten.length-1) {
            setShowVariantIndex(0);
        } else {
            setShowVariantIndex(showVariantIndex + 1);
        }
    }
    const decreaseShowVariantIndex = () => {
        if (showVariantIndex-1 < 0) {
            setShowVariantIndex(product.Varianten.length-1);
        } else {
            setShowVariantIndex(showVariantIndex - 1);
        }
    }

    const openConfigurator = (productId: string, linkData: ValvePilotProductProduktRepraesentantFuerKonfigurator) => {
        let dataString = '';

        for (const [key, value] of Object.entries(linkData)) {
            dataString = dataString + `{"attribute": "${key}", "value": "${value}"},`
        }
        window.open(configuratorUrl + linkData.EASYKAT_CFG_OBJECT + "&cfgPreselection=[" + dataString + ']', "_blank");
    }

    const variant = product.Varianten?.[showVariantIndex] as ValvePilotProductVariants;

    let mLabel;
    if (variant.DeliveryTime) {
        if (variant.DeliveryTime <= -1) {
            mLabel = mExtra;
        }
        if (variant.DeliveryTime > -1 && variant.DeliveryTime <= 2 ) {
            mLabel = mBase;
        }
        if (variant.DeliveryTime > 2) {
            mLabel = mEdit;
        }
    }

    const info = 'Info' + i18n.language.toLowerCase().charAt(0).toUpperCase() + i18n.language.toLowerCase().slice(1) as keyof ValvePilotProductVariants;
    const link = 'Link' + i18n.language.toLowerCase().charAt(0).toUpperCase() + i18n.language.toLowerCase().slice(1) as keyof ValvePilotProductVariants;

    return <Card sx={{backgroundColor: 'transparent', paddingBottom: '0.5rem'}}>
        <Header sx={{fontSize: '0.75rem'}}>
            {/*@ts-ignore*/}
            <span>{variant[info]}</span><span>{variant.Name}</span>
        </Header>

        { variant && <CardContent sx={{padding: 0, paddingTop: '0.5rem'}}>
            <Grid spacing={1} container>
                <Grid item xs={6}>
                    <DetailsContainer >
                        <Box sx={{textAlign: 'center'}}>
                            {variant.ImageURL && <ProductImage src={variant.ImageURL} alt={variant.Name + ' ' + variant.Variant}/>}
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', height: '2rem'}}>
                            {product.Varianten.length > 1 && <Box sx={{display: 'flex', gap: 0.5, textAlign: 'center'}}>
                                <IconButton variant="contained" color="inherit" onClick={decreaseShowVariantIndex}><SvgIcon component={ArrowLeft} inheritViewBox /></IconButton>
                                <Box sx={{width: '2rem', height: '2rem', lineHeight: 2}}>{showVariantIndex+1 + '/' + product.Varianten.length}</Box>
                                <IconButton variant="contained" color="inherit" onClick={increaseShowVariantIndex}><SvgIcon component={ArrowRight} inheritViewBox /></IconButton>
                            </Box>}
                            {mLabel && <LabelImage sx={{marginLeft: 'auto', marginTop: 0.125}} src={mLabel}/>}
                        </Box>
                    </DetailsContainer>
                </Grid>
                <Grid item xs={6}>
                    <DetailsContainer>
                        <Box sx={{display: 'flex', flexDirection: 'column',gap: 1}}>
                            {variant.DeliveryTime && variant.DeliveryTime === -1 && <IconLabel><SvgIcon component={WorkingDays} inheritViewBox sx={{marginRight: 1}}/>{t('Global.DELIVERY_TIME_ON_DEMAND')}</IconLabel>}
                            {variant.DeliveryTime && variant.DeliveryTime >= 1 && <IconLabel><SvgIcon component={WorkingDays} inheritViewBox sx={{marginRight: 1}}/>{t('Global.DELIVERY_TIME_DAYS', {deliveryTime: variant.DeliveryTime})}</IconLabel>}
                            {variant.isCheapest && <IconLabel><SvgIcon component={Cheapest} inheritViewBox sx={{marginRight: 1}}/>{t('Global.CHEAPEST')}</IconLabel>}
                            {variant.diameterLargerThanCalculated && <IconLabel><SvgIcon component={LargerNominalWidth} inheritViewBox sx={{marginRight: 1}}/>{t('Global.LARGER_NOMINAL_WIDTH')}</IconLabel>}
                            {variant.diameterSmallerThanCalculated && <IconLabel><SvgIcon component={SmallerNominalWidth} inheritViewBox sx={{marginRight: 1}}/>{t('Global.SMALLER_NOMINAL_WIDTH')}</IconLabel>}
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column',gap: 1}}>
                            {/*@ts-ignore*/}
                            {variant[link] && <Button href={variant[link]} target="_blank" color="primary" variant="contained" startIcon={<SvgIcon component={ArrowRight} inheritViewBox />} fullWidth>{t('Global.PRODUCT_LINK_DESC')}</Button>}
                            {!variant.ProduktRepraesentantFuerKonfigurator.DISABLE_CONFIGURATION && <Button color="primary" variant="contained" startIcon={<SvgIcon component={ArrowRight} inheritViewBox />} fullWidth onClick={() => openConfigurator(product.ProductId!, variant.ProduktRepraesentantFuerKonfigurator!)}>{t('Global.CONFIGURE_BUTTON')}</Button>}
                        </Box>
                    </DetailsContainer>
                </Grid>

                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.DN')}}/>
                            <Grid item xs>{variant.DN}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.PN')}}/>
                            <Grid item xs>{variant.PSMax}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.P1')}}/>
                            <Grid item xs>{variant.P1}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.P2')}}/>
                            <Grid item xs>{variant.P2}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.Kvs')}}/>
                            <Grid item xs>{variant.KVS}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
                <Grid item xs={6}>
                    <GreyBox>
                        <Grid spacing={1} container>
                            <Grid item xs={4} dangerouslySetInnerHTML={{__html: t('Devices.T')}}/>
                            <Grid item xs>{variant.T}</Grid>
                        </Grid>
                    </GreyBox>
                </Grid>
            </Grid>
        </CardContent> }
    </Card>
};

export default ValvePilotProductCard;
